
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiEndpoint";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "stipend-course-list",
  components: {
    Form,
    Field,
    Datatable,
  },
  data() {
    return {
      stipendCourseList: [],
      batch: {
        tranche_id: "" as any,
        entity_id: "" as any,
      },

      courseHeader: [
        {
          name: "Code.",
          key: "code",
          sortable: true,
        },
        {
          name: "Name",
          key: "course_name",
          sortable: true,
          width: "200px",
        },
        {
          name: "Tranche",
          key: "tranche",
          sortable: true,
        },
        {
          name: "Type",
          key: "course_type",
          sortable: true,
        },
        {
          name: "Term",
          key: "term",
          sortable: true,
        },
        {
          name: "Session",
          key: "session",
          sortable: true,
        },
        {
          name: "Mgt Course?",
          key: "isManagementCourse",
          sortable: true,
          width: "200px",
        },
        {
          name: "Stipend Course?",
          key: "isStipendCourse",
          sortable: true,
          width: "200px",
        },
        {
          name: "Tot Course?",
          key: "isTotCourse",
          sortable: true,
          width: "200px",
        },
        {
          name: "Employment Eligible?",
          key: "isEmploymentEligible",
          sortable: true,
          width: "200px",
        },
        {
          name: "Month",
          key: "month",
          sortable: true,
        },
        {
          name: "Hour",
          key: "hour",
          sortable: true,
        },
        {
          name: "Total Training Days",
          key: "ttd",
          sortable: true,
          width: "200px",
        },

        {
          name: "Total Target Trainee",
          key: "total_target_trainee",
          sortable: true,
          width: "200px",
        },
        {
          name: "Unit Cost",
          key: "unit_cost",
          sortable: true,
          width: "170px",
        },
      ],
      loading: false,
      courseInfoData: false,
      showDetails: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    this.batch.tranche_id = this.$route.params.tranche;
    this.batch.entity_id = this.$route.params.entity;
    await this.getStipendCourse();
  },
  methods: {
    async getStipendCourse() {
      await ApiService.get(
        this.VUE_APP_STIPEND_COURSE_LIST_API +
          "?tranche_id=" +
          this.batch.tranche_id +
          "&entity_info_id=" +
          this.batch.entity_id
      )
        .then((response) => {
          console.log(response);
          this.stipendCourseList = response.data.data;
          this.showDetails = true;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    // courseInfo() {
    //   this.courseInfoData = true;
    // },
    // courseNotice() {
    //   this.showCourseNotice = true;
    // },
    // trainingProviderNotice() {
    //   this.showtrainingProviderNotice = true;
    // },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
